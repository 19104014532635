import request from "@/utils/request";

/**
 * 我的收藏列表
 * @param {*} data 
 * @param {String} page || 页码
 * @param {String} pageSize || 页码大小
 * @param {String} sortProperty || 排序
 * @param {String} keyword || 关键词
 * @param {Boolean} isEnable || 是否启用
 * @returns 
 */
export const getCourseLists = (data) => {
  return request({
    url: "/api/app/course/query",
    method: "post",
    data: data,
  });
};


/**
 * 获取课程详情
 * @param {String} id || 课程id
 * @returns 
 */
export const getCollegeDetails = (id) => {
    return request({
        url: "/api/app/course/" + id,
        method: "get"
    });
};

/**
 * 播放信息明细
 * @param {String} id || 课程id
 * @returns 
 */
export const getPlayDetail = (id) => {
  return request({
      url: "/api/app/course/playDetail/" + id,
      method: "get"
  });
};