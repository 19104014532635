<template>
    <div class="pages">
        <pageHeader></pageHeader>
        <div class="box">
            <div class="main">
                <div class="banner">
                    <img :src="bannerUrl">
                </div>
                <div class="college-tab">
                    <div class="college-tab-lists">
                        <div class="college-tab-item" :class="tabIdx === 0 ? 'college-tab-item-active' : ''">
                            <img src="../../static/icon/college-class-icon.png">
                            <span>
                                推荐
                            </span>
                        </div>
                    </div>
                    <div class="tab-more"></div>
                </div>
                <div class="lists">
                    <div class="item" v-for="(item, index) in collegeLists" :key="index" @click="goDetails(item.id)">
                        <div class="item-top">
                            <img :src="item.cover">
                            <span class="title">{{ item.title }}</span>
                        </div>
                        <div class="item-footer">
                            <span class="no-price" v-if="item.price === 0">免费</span>
                            <span class="price" v-else>￥{{ item.price }}</span>
                            <span class="sign-num">{{ item.bCount }}人 报名</span>
                        </div>
                    </div>
                    <noData v-if="collegeLists.length === 0"></noData>
                    <getMore @getMore="getMore" :state="getMoreState"></getMore>
                </div>
            </div>
        </div>
        <pageFooter></pageFooter>
    </div>
</template>

<script>
import pageHeader from '@/components/pageHeader.vue'
import pageFooter from '@/components/pageFooter.vue'
import noData from '@/components/noData.vue'
import getMore from '@/components/getMore.vue'
import { getCourseLists } from '@/api/college.js'
import { getInfo } from '@/api/homeRight.js'
export default {
    name: "college",
    components: {
        pageHeader,
        pageFooter,
        noData,
        getMore
    },
    data() {
        return {
            tabIdx: 0,
            page: 0,
            pageSize: 10,
            total: null,
            getMoreState: 'getMore',
            collegeLists: [],
            bannerUrl:''
        }
    },
    created() {
        this.getBanner()
        this.getCourseLists()
    },
    methods: {
        /**
         * 获取banner
         */
        getBanner() {
            getInfo('CollegeBanners').then(res => {
                this.bannerUrl = res[0].url
            })
        },
        /**
         * 加载更多
         */
        getMore() {
            this.getMoreState = 'loading'
            setTimeout(() => {
                this.getCourseLists()
            }, 800)
        },
        /**
         * 获取列表
         */
        getCourseLists() {
            if (this.total === this.collegeLists.length) {
                this.getMoreState = 'noMore'
                return
            }
            this.page++
            getCourseLists({
                page: this.page,
                pageSize: this.pageSize,
                filter: {}
            }).then(res => {
                this.collegeLists = this.collegeLists.concat(res.items)
                this.total = res.totalCount
                if (res.items.length < this.pageSize) {
                    this.getMoreState = 'noMore'
                } else {
                    this.getMoreState = 'getMore'
                }
            })
        },
        /**
         * 查看详情
         */
        goDetails(id) {
            this.$router.push({ path: "/collegeDetails", query: { id: id } });
        }
    },
    mounted() {
        // 初始化播放器
        // this.player = new Aliplayer({
        //     "id": "J_prismPlayer", // 播放器容器id
        //     "autoplay": false, // 是否自动播放
        //     "controlBarVisibility": "hover", // 控制栏的显示方式
        //     "useH5Prism": true, // 是否使用H5播放器
        //     "vid": "视频ID", // 视频ID
        //     "playauth": "播放凭证", // 播放凭证
        //     // 其他播放器配置...
        // }, function (player) {
        //     console.log('播放器创建好了。')
        // });
    },
    // beforeDestroy() {
    //     // 销毁播放器实例
    //     if (this.player) {
    //         this.player.dispose();
    //     }
    // }
}
</script>

<style scoped>
.pages {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: calc(100vh - 120px);
    background-color: #F7F7F8;
}

.main {
    width: 100%;
    min-height: calc(100vh - 168px);
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.banner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.banner img{
    width: 1200px;
    height: 300px;
}

.college-tab {
    width: 1200px;
    height: 60px;
    margin-top: 38px;
    border-radius: 60px;
    background: linear-gradient(90deg, #FFFFFF 0%, #F3E3E3 95%, #F2E1E1 100%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
}

.college-tab-lists {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
}

.college-tab-lists .college-tab-item {
    width: 102px;
    height: 40px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.college-tab-lists .college-tab-item-active {
    border: 1px solid #DD0722;
    background: rgba(221, 7, 34, 0.06);
}

.college-tab-lists .college-tab-item img {
    width: 40px;
    height: 40px;
    margin-right: 5px;
}

.college-tab-lists .college-tab-item span {
    color: #3E454D;
    font-size: 16px;
}

.lists {
    width: 1200px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 33px;
}

.lists .item {
    width: 282px;
    height: 274px;
    margin-right: 24px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    cursor: pointer;
    padding-bottom: 12px;
    border-radius: 8px;
    overflow: hidden;
}

.lists .item .item-top {
    width: 282px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.lists .item .item-top img {
    width: 280px;
    height: 170px;
}

.lists .item .item-top .title {
    width: 258px;
    margin: 12px;
    color: #06121E;
    font-size: 16px;
}

.lists .item .item-footer {
    margin-top: 2px;
    width: 258px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.lists .item .item-footer .no-price {
    color: #424D57;
    font-size: 14px;
    width: 30px;
    height: 20px;
    background-color: #E7E8EB;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lists .item .item-footer .price {
    color: #E2450A;
    font-size: 16px;
}

.lists .item .item-footer .sign-num {
    color: #63676D;
    font-size: 14px;
}</style>
