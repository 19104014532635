import request from "@/utils/request";


/**
 * 热门文章
 * @param {Number} top || 数量
 * @returns 
 */
export const getpopularLists = (data) => {
    return request({
        url: "/api/app/articleApp/hot",
        method: "get",
        params: data,
    });
};


/**
 * 热度话题
 * @param {Number} top || 数量
 * @returns 
 */
export const gethotTopLists = (data) => {
    return request({
        url: "/api/app/articleApp/hotTopics",
        method: "get",
        params: data,
    });
};

/**
 * 获取联系方式
 * @returns 
 */
export const getInfo = (code) => {
    return request({
        url: "/api/app/info/" + code,
        method: "get"
    });
};